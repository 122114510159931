import axios from "axios";
import {API_URL} from "@/const";
import moment from "moment";
import { saveAs } from "file-saver";

export default {
  data() {
    return {

    };
  },

  methods: {

    //ルートのCSVアップロードAPIを実行
    postRouteCsvupload(file) {
      const params = new FormData();
      params.append("file", file);
      return new Promise((resolve, reject) => {
        axios
        .post(API_URL.ROUTE.CSV_UPLOAD, params, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err);
          reject(err)
        });
      })
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.routeDownloadErrMsg = [];
      this.isDownloading = true;
      axios
          .post(API_URL.ROUTE.CSV_DOWNLOAD
              , this.getCSVDownloadSearchParam()
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `ルート検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.finishedDownloadCSVHandler();
          })
          .catch(async (err) => {
            let responseObj = await new Response(err.response.data).json();
            this.routeDownloadErrMsg.push(responseObj.message);
            this.finishedDownloadCSVHandler();
          });
    },

    // CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParam() {
      const searchParam = JSON.parse(JSON.stringify(this.searchParam))
      let routeNameList = [];
      let routeIdList= [];
      let wasteNameList= [];
      let generateCompanyList = [];
      let generateStoreList = [];
      let wasteTypeIdList = [];
      let industrialWasteJwnetTypeIdList = [];
      let manifestTypeIdList = [];
      let changeStoreTypeIdList = [];
      let labelSettingTypeIdList = [];

      if (searchParam.routeNameSearchText) {
        if (searchParam.routeNameSearchText.match(/\s/)) {
          searchParam.routeNameSearchText.split(/\s+/).map((key) => {
            routeNameList.push(key);
          });
        } else {
          searchParam.routeNameSearchText.split(/,+/).map((key) => {
            routeNameList.push(key);
          });
        }
      }
      if (searchParam.routeNoSearchText) {
        searchParam.routeNoSearchText.split(/\s|,+/).map((key) => {
          routeIdList.push(key);
        });
      }
      if (searchParam.wasteNameSearchText) {
        if (searchParam.wasteNameSearchText.match(/\s/)) {
          searchParam.wasteNameSearchText.split(/\s+/).map((key) => {
            wasteNameList.push(key);
          });
        } else {
          searchParam.wasteNameSearchText.split(/,+/).map((key) => {
            wasteNameList.push(key);
          });
        }
      }
      const notNullGenerateCompanyNameList = this.searchParam.generateCompanyNameList.filter(name => name !== null);
      const notNullGenerateStoreNameList = this.searchParam.generateStoreNameList.filter(name => name !== null);
      if (notNullGenerateCompanyNameList) {
        notNullGenerateCompanyNameList.map((key) => {
          generateCompanyList.push(key);
        });
      }
      if (notNullGenerateStoreNameList) {
        notNullGenerateStoreNameList.map((key) => {
          generateStoreList.push(key);
        });
      }
      if (searchParam.wasteTypeIdList) {
        searchParam.wasteTypeIdList.map((key) => {
          wasteTypeIdList.push(key);
        });
      }
      if (searchParam.industrialWasteJwnetTypeIdList) {
        searchParam.industrialWasteJwnetTypeIdList.map((key) => {
          industrialWasteJwnetTypeIdList.push(key);
        });
      }
      if (searchParam.manifestTypeIdList) {
        searchParam.manifestTypeIdList.map((key) => {
          manifestTypeIdList.push(key);
        });
      }
      if (searchParam.changeStoreTypeIdList) {
        searchParam.changeStoreTypeIdList.map((key) => {
          changeStoreTypeIdList.push(key);
        });
      }
      if (searchParam.labelSettingTypeIdList) {
        searchParam.labelSettingTypeIdList.map((key) => {
          labelSettingTypeIdList.push(key);
        });
      }

      return {
        routeNameList: routeNameList,
        routeIdList: routeIdList,
        wasteNameList: wasteNameList,
        generateCompanyList: generateCompanyList,
        generateStoreList: generateStoreList,
        wasteTypeIdList: wasteTypeIdList,
        industrialWasteJwnetTypeIdList: industrialWasteJwnetTypeIdList,
        manifestTypeIdList: manifestTypeIdList,
        changeStoreTypeIdList: changeStoreTypeIdList,
        labelSettingTypeIdList: labelSettingTypeIdList,
        entrustId: searchParam.entrustId === "" ? null : searchParam.entrustId,
        collectCompanyId: searchParam.collectCompanyId === "" ? null : searchParam.collectCompanyId,
        collectLicenceFrom: searchParam.collectLicenceFrom === "" ? null : searchParam.collectLicenceFrom,
        collectLicenceTo: searchParam.collectLicenceTo === "" ? null : searchParam.collectLicenceTo,
        disposalCompanyId: searchParam.disposalCompanyId === "" ? null : searchParam.disposalCompanyId,
        disposalLicenceFrom: searchParam.disposalLicenceFrom === "" ? null : searchParam.disposalLicenceFrom,
        disposalLicenceTo: searchParam.disposalLicenceTo === "" ? null : searchParam.disposalLicenceTo,
        isOnlyFavorite: searchParam.isOnlyFavorite,
        isIncludingInvalid: searchParam.isIncludingInvalid,
        disposalSiteId: searchParam.disposalSite,
        sort: this.sortParams.sortColumn + ":" + this.sortParams.sortType
      };
    },

    finishedDownloadCSVHandler() {
      this.isDownloading = false;
    }
  },
};
