import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      selectGeneratorCollectList: [], //入力テキストで絞る運搬事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る処分事業者選択肢
      selectDisposalSiteList: [], //入力テキストで絞る処分事業場選択肢
      selectGenerateCompanyList: [], // 入力テキストで絞った排出事業者の選択肢
      selectGenerateStoreList: [], // 入力テキストで絞った排出事業場の選択肢
      generateCompanyList: [],
      generateStoreList: [],
      generatorCollectList: [],
      generatorDisposalList: [],
      searchRouteList: [],
      disposalSiteList: [],
    };
  },

  methods: {
    // ルート検索API 検索結果
    getRouteSearchApi(searchParam, isPaging) {
      this.routeDownloadErrMsg = []
      if (!isPaging) {
        this.searchParam = JSON.parse(JSON.stringify(searchParam));
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      this.searchParam.routeNameList = [];
      this.searchParam.routeIdList = [];
      this.searchParam.wasteNameList = [];
      this.searchRouteList = [];

      const searchParams = new URLSearchParams();
      searchParams.append("page", this.paginationParams.page);
      searchParams.append("pageSize", this.paginationParams.pageSize);
      searchParams.append("sort", `${this.sortParams.sortColumn}:${this.sortParams.sortType}`);
      
      if (this.searchParam.routeNameSearchText) {
        this.searchParam.routeNameSearchText.split(/\s+/).map((key) => {
          searchParams.append("routeNameList", key);
          this.searchParam.routeNameList.push(key);
        });
      }

      if (this.searchParam.routeNoSearchText) {
        this.searchParam.routeNoSearchText.split(/\s+/).map((key) => {
          searchParams.append("routeIdList", key);
          this.searchParam.routeIdList.push(key);
        });
      }

      if (this.searchParam.wasteNameSearchText) {
        this.searchParam.wasteNameSearchText.split(/\s+/).map((key) => {
          searchParams.append("wasteNameList", key);
          this.searchParam.wasteNameList.push(key);
        });
      }
      const notNullGenerateCompanyNameList = this.searchParam.generateCompanyNameList.filter(name => name !== null);
      const notNullGenerateStoreNameList = this.searchParam.generateStoreNameList.filter(name => name !== null);

      if (this.isSearchDetail) {
        if (this.searchParam.entrustId) searchParams.append("entrustId", this.searchParam.entrustId);
        if (notNullGenerateCompanyNameList) searchParams.append("generateCompanyList", notNullGenerateCompanyNameList);
        if (notNullGenerateStoreNameList) searchParams.append("generateStoreList", notNullGenerateStoreNameList);
        if (this.searchParam.collectCompanyId) searchParams.append("collectCompanyId", this.searchParam.collectCompanyId);
        if (this.searchParam.collectLicenceFrom) searchParams.append("collectLicenceFrom", this.searchParam.collectLicenceFrom);
        if (this.searchParam.collectLicenceTo) searchParams.append("collectLicenceTo", this.searchParam.collectLicenceTo);
        if (this.searchParam.disposalCompanyId) searchParams.append("disposalCompanyId", this.searchParam.disposalCompanyId);
        if (this.searchParam.disposalSite) searchParams.append("disposalSiteId", this.searchParam.disposalSite);
        if (this.searchParam.disposalLicenceFrom) searchParams.append("disposalLicenceFrom", this.searchParam.disposalLicenceFrom);
        if (this.searchParam.disposalLicenceTo) searchParams.append("disposalLicenceTo", this.searchParam.disposalLicenceTo);
        if (this.searchParam.wasteTypeIdList) searchParams.append("wasteTypeIdList", this.searchParam.wasteTypeIdList);
        if (this.searchParam.industrialWasteJwnetTypeIdList) searchParams.append("industrialWasteJwnetTypeIdList", this.searchParam.industrialWasteJwnetTypeIdList);
        if (this.searchParam.manifestTypeIdList) searchParams.append("manifestTypeIdList", this.searchParam.manifestTypeIdList);
        if (this.searchParam.changeStoreTypeIdList) searchParams.append("changeStoreTypeIdList", this.searchParam.changeStoreTypeIdList);
        if (this.searchParam.labelSettingTypeIdList) searchParams.append("labelSettingTypeIdList", this.searchParam.labelSettingTypeIdList);

        searchParams.append("isOnlyFavorite", this.searchParam.isOnlyFavorite);
        searchParams.append("isIncludingInvalid", this.searchParam.isIncludingInvalid);
      }

      return axios
        .get(API_URL.ROUTE.SEARCH + '?' + searchParams.toString())
        .then((res) => {
          this.searchRouteList = res.data.routeList;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.$store.commit("searchParamRoute/set", this.searchParam);
          const currentParam = this.$route.query
          const requestObj = { 'page' : parseInt(this.paginationParams.page) }
          this.addRequestToLocation(Object.assign(currentParam, requestObj))
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      // 運搬でログインする場合、運搬事業者選択肢は不要なので、APIを呼ばない
      if (this.$store.getters["userInfo/isCollector"]) return;

      // CBA・排出アカウントの場合：運搬事業者選択肢取得API： API_URL.MANIFEST.GENERATOR_COLLECT_LIST
      // 処分アカウントの場合：処分に紐づく運搬事業者選択肢取得： API_URL.DISPOSER.COLLECT_COMPANY_SELECT

      let url = "";
      if (this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]) {
        url = API_URL.MANIFEST.GENERATOR_COLLECT_LIST;
      }
      if (this.$store.getters["userInfo/isDisposer"]) {
        url = API_URL.DISPOSER.COLLECT_COMPANY_SELECT;
      }

      this.getGeneratorCollectSelection(url).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
        if (this.searchParam.collectCompanyId) {
          this.selectedGeneratorCollect = this.generatorCollectList.find(e => e.id === this.searchParam.collectCompanyId)
        }
      }).catch(() => {})
    },
    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.generatorDisposalList = data;
        this.selectGeneratorDisposalList = data;
        if (this.searchParam.disposalCompanyId) {
          this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
        }
      }).catch(() => {})
    },
    // 運搬に紐づく処分事業者選択肢取得API
    getGeneratorDisposalApiForCollect() {
      this.getDisposalCompanySelectionForCollect().then((data) => {
        this.generatorDisposalList = data;
        this.selectGeneratorDisposalList = data;
        if (this.searchParam.disposalCompanyId) {
          this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
        }
      }).catch(() => {})
    },
    // 処分事業場選択肢取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        if (this.searchParam.disposalSite) {
          this.selectedDisposalSite = this.disposalSiteList.find(e => e.id === this.searchParam.disposalSite)
        }   
      }).catch(() => {})
    },

    // 事業場選択肢取得API
    getStoreListApi() {
      return axios
        .get(API_URL.COMMON.GENERATE_STORE_LIST)
        .then((res) => {
          this.generateStoreList = res.data.generateStoreList.flatMap(c => c.store);
          this.selectGenerateStoreList = res.data.generateStoreList.flatMap(c => c.store);

          this.searchParam.generateStoreNameList.forEach( (gs, index) => {
            this.selectedGenerateStore[index] = gs ? this.generateStoreList.find(e => e.name === gs) : null
          })
          this.isLoadingSearchGenerateStore = false
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 排出事業者選択肢取得API
    getGeneratorCompanyApi() {
      // CBA・排出アカウントの場合：排出事業者選択肢取得API： API_URL.ENTRUST.GENERATE_SELECT_COMPANY
      // 運搬アカウントの場合：運搬に紐づく排出事業者選択肢取得： API_URL.COLLECTOR.GENERATE_SELECT_COMPANY
      // 処分アカウントの場合：処分に紐づく排出事業者選択肢取得： API_URL.DISPOSER.GENERATE_SELECT_COMPANY
      let url = "";
      if (this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]) {
        url = API_URL.ENTRUST.GENERATE_SELECT_COMPANY;
      }
      if (this.$store.getters["userInfo/isCollector"]) {
        url = API_URL.COLLECTOR.GENERATE_SELECT_COMPANY
      }
      if (this.$store.getters["userInfo/isDisposer"]) {
        url = API_URL.DISPOSER.GENERATE_SELECT_COMPANY
      }
      this.getGeneratorCompanySelection(url).then((data) => {
        this.generateCompanyList = data;
        this.selectGenerateCompanyList = data;
        this.searchParam.generateCompanyNameList.forEach( (gc, index) => {
          this.selectedGenerateCompany[index] = gc ? this.generateCompanyList.find(e => e.name === gc) : null
        })
        this.isLoadingSearchGenerateCompany = false
      }).catch(() => {})
    },
  },
};
