<template>
  <div class="c-modal xlg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">ルートCSVアップロード</h5>
        <span
          class="c-modal__head__close"
          @click="hideCsvUploadModal"
        ></span>
      </div>
      <div class="c-modal__body">
        <ErrorMessage :errMsgs="csvUploadModalErrMsgs" />
        <Content 
          ref="contentComponent"
          v-bind:onClickUploadButton="onClickUploadCsv"
          v-bind:initErrorMessage="initCsvUploadModalErrorMessage"
        />
      </div>  
      <div class="c-modal__foot">
        <div
          class="c-btn secondary small"
          @click="hideCsvUploadModal"
        >
          閉じる
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Content from "@/components/parts/CsvUploadModalContent";

export default {
  components: {Content},
  props: {
    onClickCloseButton: {
      type: Function,
    },
    onClickUploadButton: {
      type: Function,
    },
  },
  data() {
    return {
      csvUploadProcessing: false,
      csvUploadModalErrMsgs: [],
    }
  },
  methods: {
    hideCsvUploadModal() {
      this.initCsvUploadModalErrorMessage();
      this.onClickCloseButton();
      this.$refs.contentComponent.deleteSelectedCsv();
    },

    initCsvUploadModalErrorMessage() {
      this.csvUploadModalErrMsgs = [];
    },

    onClickUploadCsv(selectedCsvFile) {
      this.csvUploadProcessing = true;
      this.initCsvUploadModalErrorMessage();
      return this.onClickUploadButton(selectedCsvFile)
        .then(() => {
          this.csvUploadProcessing = false;
        })
        .catch(err => {
          this.csvUploadProcessing = false;
          if (err && err.response && err.response.data && err.response.data.message) {
            this.csvUploadModalErrMsgs = [err.response.data.message];
          }
          if (err && err.response && err.response.status && err.response.status === 500) {
            this.csvUploadModalErrMsgs = ["想定外のエラーが発生しました"];
          }
        })
    },
  }
}
</script>

